import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#000000',
                secondary: '#FFFFFF',
                accent: '#ADD8E6',
                error: '#FF0000',
            },
        },
        options: { customProperties: true },
    },
});
