<template>
    <GmapMap
        :center="center"
        :zoom="zoom"
        :style="{width: '100%', height: '100%'}"
        @zoom_changed="zoom = $event"
        :options="mapOptions">
        <gmapInfoWindow 
            :options="infoOptions" 
            :position="infoWindowPos" 
            :opened="infoWinOpen" 
            @closeclick="infoWinOpen=false; $emit('selected-marker', {})"/>
        <GmapMarker
            :key="index"
            :icon="{ url: require('../assets/marker.png'), scaledSize: {width: 42, height: 53}}"
            v-for="(m, index) in markers"
            :position="{lat: m.Lat, lng: m.Lng}"
            :clickable="true"
            :draggable="false"
            @click="$emit('selected-marker', m)"/>
    </GmapMap>
</template>

<script>
export default {
    props: {
        markers: {
            type: Array,
            required: true
        },
        selectedMarker: {
            type: Object,
            required: true
        },
        center: {
            type: Object,
            required: true
        },
    },
    watch: {
        selectedMarker: function() {
            if (this.$props.selectedMarker.Id){
                this.toggleInfoWindow(this.$props.selectedMarker)
            } else {
                this.infoWinOpen = false
                this.currentMidx = null
            }
            
        }
    },
    data() {
        return {
            zoom: 10,
            infoWinOpen: false,
            infoWindowPos: {},
            currentMidx: null,
            infoOptions: {
                content: '',
                //optional: offset infowindow so it visually sits nicely on top of our marker
                pixelOffset: {
                    width: 0,
                    height: -50
                }
            },
        }
    },
    methods: {
        toggleInfoWindow: function(marker) {
            this.infoWindowPos = {lat: marker.Lat, lng: marker.Lng}
            if (marker.Phone) {
                this.infoOptions.content = `<h4>${marker.Name}</h4><br/>
                    <div>${marker.Address}</div><br>
                    <div>Phone Number: ${marker.Phone}</div>`
            } else {
                this.infoOptions.content = `<h4>${marker.Name}</h4><br/>
                    <div>${marker.Address}</div><br>`
            }
            
            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == marker.Id) {
              this.infoWinOpen = !this.infoWinOpen;
            } else {
            //if different marker set infowindow to open and reset current marker index
              this.$emit('selected-marker', marker)
              this.$emit('center', {lat: marker.Lat, lng: marker.Lng})
              this.zoom = 13
              this.infoWinOpen = true;
              this.currentMidx = marker.Id;
            }
        },
    },
    computed: {
        mapOptions() {
            return {
                zoomControl: true,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                styles: [{
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [{
                        visibility: "off"
                    }]
                }]
            }
        },
    }

}
</script>

<style>

</style>