import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import GmapVue from 'gmap-vue';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

Vue.config.productionTip = false
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: 'AIzaSyBLtgt3UMQ2ltcyWY13zslFA6nSLUoeht4'
});
Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyBLtgt3UMQ2ltcyWY13zslFA6nSLUoeht4',
    libraries: 'places',
    v: '3.6'
  },
  autoBindAllEvents: false,
  installComponents: true,
  dynamicLoad: false,
})
new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
