<template>
  <v-app>
    <v-main>
      <home_comp/>
    </v-main>
  </v-app>
</template>

<script>
import home_comp from './components/home-page.vue';

export default {
  name: 'Store-Locator',

  components: {
    home_comp,
  },

  data: () => ({
    //
  }),
};
</script>
